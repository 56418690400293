import React, { useState } from 'react';
import './Registration.css';

function Registration({ onClose, toggleForm }) {
  const [formData, setFormData] = useState({
    Name: '',
    Role: '',
    Email: '',
    Password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(JSON.stringify(formData, null, 2));
    try {
      const response = await fetch('https://monitoring.kvantmaster.ru/rest/v1/auth:registration', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Success: ', result);
      } else {
        console.error('Error: ', response.statusText);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
    onClose();
  };

  return (
    <div className="registration-block">
      <div className="registration-container">
        <div className="registration-header">
          <h2 className="registration-text">Регистрация</h2>
        </div>
        <div className="registration-content">
          <form className="registration-form" name="registration" onSubmit={handleSubmit}>
            <input
              type="text"
              name="Name"
              className="form-input input_type_name-surname"
              placeholder="Фамилия и Имя"
              id="name-surname-input"
              minLength="2"
              maxLength="50"
              pattern="[a-zA-zа-яА-яёЁ\s\-]+$"
              data-error-message="Разрешены только латинские, кириллические буквы, знаки дефиса и пробелы"
              required
              value={formData.Name}
              onChange={handleChange}
            />
            <input
              type="text"
              name="Role"
              className="form-input input_type_role"
              placeholder="Роль"
              id="role"
              minLength="2"
              maxLength="50"
              pattern="[a-zA-zа-яА-яёЁ\s\-]+$"
              data-error-message="Разрешены только латинские, кириллические буквы, знаки дефиса и пробелы"
              required
              value={formData.Role}
              onChange={handleChange}
            />
            <input
              type="email"
              name="Email"
              className="form-input input_type_email"
              placeholder="Почта"
              id="email-input"
              minLength="2"
              maxLength="50"
              required
              value={formData.Email}
              onChange={handleChange}
            />
            <input
              type="password"
              name="Password"
              className="form-input input_type_password"
              placeholder="Пароль"
              id="password-input"
              minLength="8"
              maxLength="50"
              required
              value={formData.Password}
              onChange={handleChange}
            />
            <button type="submit" className="button form__button">Сохранить</button>
            <p onClick={toggleForm} style={{ cursor: 'pointer', color: 'blue' }}>Уже зарегистрированы?</p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Registration;
