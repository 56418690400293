import './Footer.css';

function Footer() {
  return (
    <footer className="template__footer">
      <h2 className="made-by">Made by MIEM students</h2>
    </footer>
  );
}

export default Footer;