// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.template__footer {
  block-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #edeff3;

}

.made-by {
  font-size: 18px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;;AAE3B;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".template__footer {\n  block-size: 50px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #edeff3;\n\n}\n\n.made-by {\n  font-size: 18px;\n  font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
