import React, { useState } from 'react';
import Login from '../Login/Login';
import Registration from '../Registration/Registration';

function AuthForm({ onClose }) {
  const [isLogin, setIsLogin] = useState(true);

  const toggleForm = () => {
    setIsLogin(!isLogin);
  };

  return (
    <div className="auth-form-block">
      <div className="auth-form-container">
        {isLogin ? (
          <Login onClose={onClose} toggleForm={toggleForm} />
        ) : (
          <Registration onClose={onClose} toggleForm={toggleForm} />
        )}
      </div>
    </div>
  );
}

export default AuthForm;