// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
p {
  padding: 0;
  margin: 0;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.root{
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/globalStyles/globals.css"],"names":[],"mappings":"AAAA;EACE;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,SAAS;EACT,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE;aACW;AACb;;AAEA;;;EAGE,UAAU;EACV,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,SAAS;AACX;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB","sourcesContent":["body {\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  margin: 0;\n  padding: 0;\n  overflow: hidden;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\nh1,\nh2,\np {\n  padding: 0;\n  margin: 0;\n}\n\nul {\n  list-style-type: none;\n  padding: 0;\n  margin: 0;\n}\n.root{\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
