// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registration-block {
  display: flex;
  align-items: center;
  justify-content: center;
  block-size: calc(100vh - 153px);
}

.registration-container {
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
  block-size: 400px;
  inline-size: 450px;
  border: 2px solid #000;
  border-radius: 20px;
  background-color: white;
}

.registration-header {
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: 100%;
  block-size: 45px;
  border-block-end: 2px solid #000;
  font-size: 20px;
}

.registration-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  block-size: 355px;
}

.registration-form {
  block-size: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.form-input {
  inline-size: 180%;
  block-size: 25px;
  border-radius: 20px;
  padding: 5px;
  font-size: 14px;
}

.form__button {
  background-color: transparent;
  block-size: 30px;
  inline-size: 100px;
  border-radius: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Registration/Registration.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,gBAAgB;EAChB,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".registration-block {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  block-size: calc(100vh - 153px);\n}\n\n.registration-container {\n  display: flex;\n  align-items: center;\n  justify-self: center;\n  flex-direction: column;\n  block-size: 400px;\n  inline-size: 450px;\n  border: 2px solid #000;\n  border-radius: 20px;\n  background-color: white;\n}\n\n.registration-header {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  inline-size: 100%;\n  block-size: 45px;\n  border-block-end: 2px solid #000;\n  font-size: 20px;\n}\n\n.registration-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  block-size: 355px;\n}\n\n.registration-form {\n  block-size: inherit;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 15px;\n}\n\n.form-input {\n  inline-size: 180%;\n  block-size: 25px;\n  border-radius: 20px;\n  padding: 5px;\n  font-size: 14px;\n}\n\n.form__button {\n  background-color: transparent;\n  block-size: 30px;\n  inline-size: 100px;\n  border-radius: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
