import './User.css'
import userImage from '../public/Photo.png'

function User() {
  return (
    <div className="user-show">
      <div className="user-show__container">
        <ul className="user__options-list">
          <li className="user__options-list-item">Опция</li>
          <li className="user__options-list-item">Опция</li>
          <li className="user__options-list-item">Опция</li>
          <li className="user__options-list-item">Опция</li>
          <li className="user__options-list-item">Опция</li>
        </ul>
        <div className="user-show-info__container">
          <div className="user-show-image__container">
            <img src={ userImage } alt="Фото Пользователя" className="user-show-image" />
          </div>
          <div className="user-show__name">
            <p>Иванов Иван</p>
          </div>
          <ul className="user-show-options-list">
            <li className="user-show-options-list-item">Опция</li>
            <li className="user-show-options-list-item">Опция</li>
            <li className="user-show-options-list-item">Опция</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default User;