import React, { useState } from 'react';
import './AddPhoto.css';
import addPhotoImage from '../public/Component.png';

function AddPhoto() {
  const [tags, setTags] = useState([]);
  const [tagName, setTagName] = useState('');
  const [tagValue, setTagValue] = useState('');

  const handleTagNameChange = (e) => {
    setTagName(e.target.value);
  };

  const handleTagValueChange = (e) => {
    setTagValue(e.target.value);
  };

  const handleAddTag = (e) => {
    e.preventDefault();
    if (tagName.trim() !== '' && tagValue.trim() !== '') {
      setTags([...tags, `${tagName.trim()}: ${tagValue.trim()}`]);
      setTagName('');
      setTagValue('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Здесь можно добавить логику для отправки формы
    console.log('Форма отправлена');
  };

  return (
    <div className="add-photo-content">
      <form onSubmit={handleFormSubmit} className="add-photo__form">
        <div className="add-photo-image__container">
          <img src={addPhotoImage} alt="Фото Компонента" className="add-photo-image" />
        </div>
        <div className="image-info">
          <input
            type="text"
            className="form-input input_type_component_name"
            name="component_name"
            placeholder="Название"
            minLength="2"
            required
          />
          <textarea
            className="form-input input_type_info"
            rows="10"
            cols="40"
            maxLength="400"
            minLength="2"
            placeholder="Описание"
            id="info-input"
            name="info"
          ></textarea>
          <div className="tags-input__container">
            <input
              type="text"
              className="form-input input_type_tag_name"
              name="tag_name"
              placeholder="Введите имя тега"
              minLength="2"
              required
              value={tagName}
              onChange={handleTagNameChange}
            />
            <input
              type="text"
              className="form-input input_type_tag_value"
              name="tag_value"
              placeholder="Введите значение"
              minLength="2"
              required
              value={tagValue}
              onChange={handleTagValueChange}
            />
            <button type="button" className="button tag__button" onClick={handleAddTag}>+</button>
          </div>
          <button type="submit" className="button form__button">Сохранить</button>
        </div>
        <div className="tags__container">
          {tags.map((tag, index) => (
            <span key={index} className="tag">
              {tag}
              <button type="button" className="tag__remove-button" onClick={() => handleRemoveTag(tag)}>
                x
              </button>
            </span>
          ))}
        </div>
      </form>
    </div>
  );
}

export default AddPhoto;
