import React, { useState, useEffect } from 'react';
import './Gallery.css';
import './Modal.css';
import componentImage from '../public/Component.png';

const Gallery = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState('');

  const openModal = (image, description) => {
    setSelectedImage(image);
    setSelectedDescription(description);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isModalOpen]);

  const photos = [
    { image: componentImage, description: 'Описание 1' },
    { image: componentImage, description: 'Описание 2' },
    { image: componentImage, description: 'Описание 3' },
    { image: componentImage, description: 'Описание 4' },
    { image: componentImage, description: 'Описание 5' },
    { image: componentImage, description: 'Описание 6' },
    { image: componentImage, description: 'Описание 7' },
  ];

  return (
    <div className="gallery-block">
      <div className="gallery__container">
        <div className="photos__container">
          <div className="photos">
            {photos.map((photo, index) => (
              <div
                key={index}
                className="photo-card"
                onClick={() => openModal(photo.image, photo.description)}
              >
                <div className="photo__container">
                  <img src={photo.image} alt="Фото Компонента" className="component-image" />
                </div>
                <div className="photo-description">
                  <p>{photo.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="filter__container">
          <div className="filter">
            <h2 className="filter__header">Фильтр</h2>
            <div className="search-form__checkbox-list">
              <label className="search-form__label">
                <input
                  type="checkbox"
                  name="time"
                  value="morning"
                  className="search-form__checkbox visually-hidden"
                />
                <span className="search-form__pseudo-checkbox"></span>
                <span className="search-form__label-text">Утро</span>
              </label>
              <label className="search-form__label">
                <input
                  type="checkbox"
                  name="time"
                  value="afternoon"
                  className="search-form__checkbox visually-hidden"
                />
                <span className="search-form__pseudo-checkbox"></span>
                <span className="search-form__label-text">День</span>
              </label>
              <label className="search-form__label">
                <input
                  type="checkbox"
                  name="time"
                  value="afternoon"
                  className="search-form__checkbox visually-hidden"
                />
                <span className="search-form__pseudo-checkbox"></span>
                <span className="search-form__label-text">Вечер</span>
              </label>
              <label className="search-form__label">
                <input
                  type="checkbox"
                  name="time"
                  value="night"
                  className="search-form__checkbox visually-hidden"
                />
                <span className="search-form__pseudo-checkbox"></span>
                <span className="search-form__label-text">Ночь</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close-button" onClick={closeModal}>
              &times;
            </button>
            <img src={selectedImage} alt="Выбранное изображение" className="modal-image" />
            <p className="modal-description">{selectedDescription}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
