import React, { useState } from 'react';
import './Login.css';

function Login({ onClose, toggleForm }) {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(JSON.stringify(formData, null, 2));
    onClose(); 
  };

  return (
    <div className="authorisation-block">
      <div className="authorisation-container">
        <div className="authorisation-header">
          <h2 className="authorisation-text">Авторизация</h2>
        </div>
        <div className="authorisation-content">
          <form className="authorisation-form" name="authorisation" onSubmit={handleSubmit}>
            <input
              type="text"
              name="email"
              className="form-input input_type_email"
              placeholder="Почта"
              id="email-telephone-input"
              minLength="2"
              maxLength="50"
              required
              value={formData.email}
              onChange={handleChange}
            />
            <input
              type="text"
              name="password"
              className="form-input input_type_password"
              placeholder="Пароль"
              id="password-input"
              minLength="8"
              maxLength="50"
              required
              value={formData.password}
              onChange={handleChange}
            />
            <button type="submit" className="button form__button">Сохранить</button>
            <p onClick={toggleForm} style={{ cursor: 'pointer', color: 'blue' }}>Не зарегистрированы?</p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
