import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../public/Logo.png';
import userImage from '../public/Photo.png';
import './Header.css';
import './Modal.css';
import '../Login/Login.css';
import Login from '../Login/Login';
import AuthForm from '../AuthForm/AuthFrom';

function Header() {
  const [isAuthFormOpen, setIsAuthFormOpen] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const openAuthForm = () => {
    setIsAuthFormOpen(true);
  };

  const closeAuthForm = () => {
    setIsAuthFormOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleOptionClick = (option) => {
    console.log(`Option clicked: ${option}`);
    setDropdownVisible(false);
  };

  useEffect(() => {
    if (isAuthFormOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isAuthFormOpen]);

  return (
    <header className="template__header">
      <nav className="header__menu">
        <div className="logo-and-name">
          <div className="logo__container">
            <Link to="/" className="link-to-welcome-page">
              <img src={logo} alt="Логотип Миэма" className="miem-logo" />
            </Link>
          </div>
          <div className="project__name">
            <Link to="/" className="link-to-welcome-page header__link">
              Проект 1904
            </Link>
          </div>
        </div>
        <div className="header__options">
          <ul className="header__links-list">
            <li className="header__links-list-item">
              <Link to="/" className="header__link">Главная</Link>
            </li>
            <li className="header__links-list-item">
              <Link to="/gallery" className="header__link">Галерея</Link>
            </li>
            <li className="header__links-list-item">
              <Link to="/add-photo" className="header__link">Добавить фото</Link>
            </li>
            <li className="header__links-list-item">
              <Link to="/users" className="header__link">Пользователи</Link>
            </li>
          </ul>
        </div>
        <div className="user">
          <div className="user__name">
            <div className='user-link' onClick={openAuthForm}>
              <p>Вход</p>
            </div>
          </div>

          {isAuthFormOpen && (
            <div className="login-overlay" onClick={closeAuthForm}>
              <div className="login-content" onClick={(e) => e.stopPropagation()}>
                <AuthForm onClose={closeAuthForm} />
              </div>
            </div>
          )}
          <div className='user-link'>
            <div className="user-image__container" onClick={toggleDropdown}>
              <img src={userImage} alt="Логотип Миэма" className="user-image" />
            </div>
            {isDropdownVisible && (
              <div className="dropdown">
                <div className="dropdown-option" onClick={() => handleOptionClick('settings')}>
                  Настройки
                </div>
                <div className="dropdown-option" onClick={() => handleOptionClick('logout')}>
                  Выйти
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
