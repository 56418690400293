import React, { useState } from 'react';
import './Registration.css';

function Registration({ onClose, toggleForm }) {
  const [formData, setFormData] = useState({
    name_surname: '',
    email: '',
    telephone: '',
    password: '',
    password_repeat: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(JSON.stringify(formData, null, 2));
    onClose(); 
  };

  return (
    <div className="registration-block">
      <div className="registration-container">
        <div className="registration-header">
          <h2 className="registration-text">Регистрация</h2>
        </div>
        <div className="registration-content">
          <form className="registration-form" name="registration" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name_surname"
              className="form-input input_type_name-surname"
              placeholder="Фамилия и Имя"
              id="name-surname-input"
              minLength="2"
              maxLength="50"
              pattern="[a-zA-zа-яА-яёЁ\s\-]+$"
              data-error-message="Разрешены только латинские, кириллические буквы, знаки дефиса и пробелы"
              required
              value={formData.name_surname}
              onChange={handleChange}
            />
            <input
              type="text"
              name="email"
              className="form-input input_type_email"
              placeholder="Почта"
              id="email-input"
              minLength="2"
              maxLength="50"
              required
              value={formData.email}
              onChange={handleChange}
            />
            <input
              type="tel"
              name="telephone"
              className="form-input input_type_telephone"
              placeholder="Номер Телефона"
              id="telephone-input"
              minLength="11"
              maxLength="11"
              data-error-message="Разрешены только цифры"
              required
              value={formData.telephone}
              onChange={handleChange}
            />
            <input
              type="text"
              name="password"
              className="form-input input_type_password"
              placeholder="Пароль"
              id="password-input"
              minLength="8"
              maxLength="50"
              required
              value={formData.password}
              onChange={handleChange}
            />
            <input
              type="text"
              name="password_repeat"
              className="form-input input_type_password-repeat"
              placeholder="Введите пароль еще раз"
              id="password-repeat-input"
              minLength="8"
              maxLength="50"
              required
              value={formData.password_repeat}
              onChange={handleChange}
            />
            <button type="submit" className="button form__button">Сохранить</button>
            <p onClick={toggleForm} style={{ cursor: 'pointer', color: 'blue' }}>Уже зарегистрированы?</p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Registration;
