import './mainPage.css';

function MainPage() {
  return (
    <div className="welcome-message__container">
      <h1 className="welcome-message">
        Добро пожаловать.
      </h1>
    </div>
  );
}

export default MainPage;